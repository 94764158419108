import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopupGraph from './PopupGraph';

const ForecastTable = (props) => {
  const { category, data, pastMonth } = props;
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const transformedData = data.map((row) => {
    // Sum the past month values for 'Actual Cost'
    const actualCost = row.data.slice(0, pastMonth).reduce((acc, val) => acc + parseInt(val, 10));

    // Sum the remaining values for 'Forecast'
    const forecast = row.data.slice(pastMonth).reduce((acc, val) => acc + parseInt(val, 10));

    return {
      ...row,
      'Actual Cost': parseFloat(actualCost).toFixed(2),
      Forecast: parseFloat(forecast).toFixed(2),
    };
  });

  const openPopupBox = (rowIndex) => {
    const filterData = rowIndex.tableData.filter((i) => i.name === rowIndex.rowData[0])[0];
    const series = [{ name: filterData.name, data: filterData?.data?.map((i) => parseFloat(i)) }];
    setSelectedRow(series);
    setOpenPopup(true);
  };

  const onCancel = () => {
    setOpenPopup(false);
  };

  function getColumn() {
    const columns = [
      {
        name: 'name',
        options: {
          download: true,
          filter: false,
          setCellHeaderProps: () => ({
            style: {
              position: 'sticky',
              background: '#f3f6f9',
              zIndex: 8,
            },
          }),
        },
      },
    ];
    columns.push({
      name: 'Actual Cost',
      options: {
        download: true,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
      },
    });
    columns.push({
      name: 'Forecast',
      options: {
        download: true,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
      },
    });

    columns.push({
      name: 'View',
      options: {
        download: false,
        filter: false,
        viewColumns: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
        customBodyRender: (dataIndex, rowIndex) => (
          <button
            type="button"
            className="btn text-primary me-1"
            onClick={() => openPopupBox(rowIndex)}
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        ),
      },
    });
    return [...columns];
  }

  const options = {
    filter: true,
    fixedHeader: true,
    fixedSelectColumn: true,
    responsive: 'scroll',
    rowsPerPage: 5,
    expandableRows: false,
    expandableRowsHeader: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    print: false,
    filterType: 'dropdown',
    sortOrder: {
      name: 'UploadedDate',
      sort: true,
      direction: 'desc',
    },
  };

  return (
    <>
      <div className="table-responsive">
        <MUIDataTable
          title="User Training Data"
          data={transformedData}
          columns={getColumn()}
          options={options}
        />
      </div>
      {openPopup ? (
        <>
          <div className="log-out-layer" />
          <PopupGraph series={selectedRow} category={category} onCancel={onCancel} />
        </>
      ) : ''}
    </>

  );
};

export default ForecastTable;
