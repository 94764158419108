import React from 'react';
import ReactApexChart from 'react-apexcharts';
import ForecastTable from './ForecastTable';
import Loader from '../../Loader';
import HeaderInfo from '../../Common/HeaderInfo';
import ServiceTrendingToolCard from '../ServiceTrendingToolCard';

const ForecastAnalytics = (props) => {
  const {
    data, category, actualCost, forecastCost, pastMonths, futureMonths, forecastTableData,
  } = props;
  function kFormatter(num) {
    return Math.abs(num) > 999 ? `${Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1))}k` : Math.sign(num) * Math.abs(num);
  }

  const customLegendFormatter = (seriesName) => `
          <div class="legend-item-label">${seriesName}</div>
      `;

  function formatter(val) {
    if (val === undefined || val === null) {
      return null;
    }
    return `$ ${val
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  }
  const chartOptions = {
    noData: {
      text: 'No Data Available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#000000',
        fontSize: '20px',
        fontFamily: 'Helvetica',
      },
    },
    series: data,
    forecastDataPoints: {
      count: 6,
    },
    chart: {
      height: 400,
      type: 'line',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 3,
      curve: 'smooth',
    },
    tooltip: {
      y: {
        formatter(val) {
          return `$ ${val
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
        },
      },
    },
    legend: {
      position: 'bottom',
      height: 200,
      horizontalAlign: 'left',
      formatter: customLegendFormatter,
      show: true,
    },
    yaxis: {
      labels: {
        formatter(value) {
          return `$${kFormatter(value)}`;
        },
      },
    },
    xaxis: {
      type: 'datetime',
      categories: category,
      tickAmount: 10,
      labels: {
        formatter(value, timestamp, opts) {
          return opts.dateFormatter(new Date(timestamp), 'dd MMM');
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: ['#FDD835'],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
  };
  return (
    <>
      {category ? (
        <>
          <>
            <div className="d-flex">
              <ServiceTrendingToolCard title={`${pastMonths} Month Actual Cost`} amount={formatter(actualCost)} />
              <ServiceTrendingToolCard title={`${futureMonths} Month Forecast`} amount={formatter(forecastCost)} percent="12.67" amountDiff="+$1454.34" type="success" additionalClass="ms-3" />
            </div>
            <div className="card h-100">
              <div className="card-body">
                <h5>
                  <HeaderInfo headerName="Forecast Analysis" />
                </h5>
                {' '}
                <ReactApexChart
                  options={chartOptions}
                  series={chartOptions.series}
                  type="line"
                  height={400}
                  className="forecast-graph"
                />
              </div>
            </div>
            <div className="card mt-3">
              <div className="card-body">
                <ForecastTable category={category} data={forecastTableData} pastMonth={pastMonths} futureMonth={futureMonths} />
              </div>
            </div>
          </>
        </>
      )
        : <Loader />}
    </>
  );
};

export default ForecastAnalytics;
