import React from 'react';
import { navigate } from '@reach/router';

const ProtectedRoute = ({ component: Component, hasAccess, ...rest }) => {
  if (!hasAccess) {
    // Redirect to access denied page if user doesn't have access
    navigate('/accessDenied');
    return null; // Return null to prevent rendering the component
  }
  return <Component {...rest} />;
};

export default ProtectedRoute;
