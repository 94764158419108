import React from 'react';
import ReactApexChart from 'react-apexcharts';

const PopupGraph = (props) => {
  const {
    category, series, onCancel,
  } = props;

  function kFormatter(num) {
    return Math.abs(num) > 999 ? `${Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1))}k` : Math.sign(num) * Math.abs(num);
  }

  const customLegendFormatter = (seriesName) => `
          <div class="legend-item-label">${seriesName}</div>
      `;

  const chartOptions = {
    noData: {
      text: 'No Data Available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#000000',
        fontSize: '20px',
        fontFamily: 'Helvetica',
      },
    },
    series,
    forecastDataPoints: {
      count: 6,
    },
    chart: {
      height: 400,
      type: 'line',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 3,
      curve: 'smooth',
    },
    tooltip: {
      y: {
        formatter(val) {
          return `$ ${val
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
        },
      },
    },
    legend: {
      position: 'bottom',
      height: 200,
      horizontalAlign: 'left',
      formatter: customLegendFormatter,
      show: true,
    },
    yaxis: {
      labels: {
        formatter(value) {
          return `$${kFormatter(value)}`;
        },
      },
    },
    xaxis: {
      type: 'datetime',
      categories: category,
      tickAmount: 10,
      labels: {
        formatter(value, timestamp, opts) {
          return opts.dateFormatter(new Date(timestamp), 'dd MMM');
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: ['#FDD835'],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
  };

  return (
    <>
      <div
        className="modal fade reqNewAccount show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        style={{ display: 'block' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">
                Forecast Analytics for
                {' '}
                {series[0].name}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => onCancel('Cancel')}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <ReactApexChart
                options={chartOptions}
                series={series}
                type="line"
                height={400}
                className="forecast-graph"
              />
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => onCancel('Cancel')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupGraph;
