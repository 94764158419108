import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFeedbackStatusRatio } from '../Store/actions/feedbackAction';
import PageHeader from '../Components/Common/PageHeader';
import FeedbackScoreCard from '../Components/Feedback/FeedbackScoreCard';
import ModuleWidget from '../Components/ModuleWidget';
import Activities from '../Components/Latest Activity/Activities';
import adminImg from '../Images/admin.png';
import feedbackImg from '../Images/feedback.png';
import CostReportImg from '../Images/report.png';
import managementImg from '../Images/user-management.png';
import Loader from '../Components/Loader';
import DashboardFilter from '../Components/Filter/DashboardFilter';
import Alert from '../Components/Common/Alert';
import { Context } from '../Context/FeedbackContext';

const Home = () => {
  const dispatch = useDispatch();
  const feedbackList = useSelector((state) => state.feedbackList);
  const { feedbackStatusRatio, feedbackStatusRatioLoading, errorFeedbackStatusRatio } = feedbackList;
  const [feedbackContextState, setFeedbackContextState] = useContext(Context);

  const employeeList = useSelector((state) => state.employeeList);
  const {
    userAccess,
  } = employeeList;

  const {
    selectedTime,
    selectedLengthNew,
    selectedLengthAction,
    selectedPercentNew,
    selectedPercentAction,
  } = feedbackContextState;
  const [ShowErrMsg, setShowErrMsg] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasAccess, setHasAccess] = useState(false);

  const adminNavigationList = ['Provide Admin Permissions', 'Edit Account Details'];
  const costReportNavList = ['Update Ascendon Cost Report', 'View Cost Report History'];
  const feedbackNavigationList = ['Receive Feedbacks', 'Take Actions on Feedbacks'];
  const managementNavigationList = ['Add/Remove or Manage User', 'Assign permissions and more'];

  useEffect(() => {
    const apiUrl = '/api/feedback/GetFeedback';
    dispatch(getFeedbackStatusRatio(apiUrl));
  }, [dispatch]);

  useEffect(() => {
    if (feedbackStatusRatio.length > 0) {
      const newFeedbacks = feedbackStatusRatio.filter((c) => c.status === 'Open').length;
      const actionFeedbacks = feedbackStatusRatio.filter((c) => c.status !== 'Open').length;
      setFeedbackContextState({
        ...feedbackContextState,
        selectedLengthNew: newFeedbacks,
        selectedLengthAction: actionFeedbacks,
        contextFeedbackStatusRatio: feedbackStatusRatio,
        selectedPercentNew: Math.round((newFeedbacks / feedbackStatusRatio?.length) * 100),
        selectedPercentAction: Math.round((actionFeedbacks / feedbackStatusRatio?.length) * 100),
      });
    }
  }, [feedbackStatusRatio]);

  useEffect(() => {
    if (userAccess?.length > 0
      && userAccess.filter((i) => i.pageCode === 'feedback' && (i.writeAccess || i.readAccess))?.length > 0) {
      setHasAccess(true);
    }
  }, [userAccess]);

  useEffect(() => {
    if (errorFeedbackStatusRatio !== '') {
      setErrorMessage(errorFeedbackStatusRatio);
      setShowErrMsg(true);
    }
  }, [errorFeedbackStatusRatio]);
  return (
    <>
      {feedbackStatusRatioLoading ? (
        <Loader />
      ) : ShowErrMsg ? (
        <div className="col-12">
          <Alert
            message={errorMessage}
            alertClass="light-danger"
            onClose={() => setShowErrMsg(false)}
          />
        </div>
      ) : (
        <div className="wrapper">
          <div className="main">
            <div className="row">
              <div className="col-lg-9 mb-3 mb-lg-0">
                <PageHeader pageTitle="Dashboard">
                  {hasAccess
                    ? (
                      <DashboardFilter />
                    ) : ''}
                </PageHeader>
                <div className="page-content">
                  {hasAccess
                    ? (
                      <div className="dashboard-card">
                        <FeedbackScoreCard title="New Feedbacks" count={selectedLengthNew} percent={selectedPercentNew} duration={selectedTime} type={`${selectedPercentNew > 0 ? 'success' : 'danger'}`} />
                        <FeedbackScoreCard title="Feedback Actions" count={selectedLengthAction} percent={selectedPercentAction} duration={selectedTime} type={`${selectedPercentAction > 0 ? 'success' : 'danger'}`} />
                      </div>
                    ) : ''}
                  <div className="card shadow-sm">
                    <div className="card-body">
                      <div className="row mb-3 mb-md-4">
                        <ModuleWidget title="Admin Section" navigationList={adminNavigationList} imgUrl={adminImg} />
                        <ModuleWidget title="Cost Reporting" navigationList={costReportNavList} imgUrl={CostReportImg} />
                      </div>
                      <div className="row">
                        <ModuleWidget title="Feedbacks" navigationList={feedbackNavigationList} imgUrl={feedbackImg} />
                        <ModuleWidget title="User Management" navigationList={managementNavigationList} imgUrl={managementImg} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <Activities />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Home;
